import styled, { keyframes } from "styled-components";

const pulse = keyframes`
	to {
		transform: translate3d(0px, 0px, -60px);
		box-shadow: 15px 15px 20px -20px rgba(black,.8)
	}
`;

export const Grid = styled.div`
  grid-area: 1 / -1 / 1 / -1;
  backface-visibility: hidden;
  z-index: 2;
  margin: 7rem 0 5rem;
  /* background: linear-gradient(coral, rebeccapurple); */
  perspective: 600px;
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-template-rows: repeat(3, 150px);
  gap: 20px;
  transform: ${(props) =>
    `rotateZ(45deg) rotateY(calc(180deg * ${+props.flipped}))`};
  transition: transform 0.8s;
  @media screen and (max-width: 800px) {
    margin: 3rem 0 0rem;
    grid-template-columns: 150px 150px;
    grid-auto-rows: 150px;
    gap: 30px;
    transform: ${(props) => `rotateY(calc(180deg * ${+props.flipped}))`};
    div:nth-child(even) {
      display: none;
    }
  }
`;

export const GridItem = styled.div`
  padding: 30px;
  color: transparent;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
  /* border: thin solid rgba(92, 92, 92, 0.5); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:nth-child(odd) {
    animation: ${pulse} 500ms ease-in-out 2 alternate;
  }
  &:nth-child(even) {
    border: none;
    box-shadow: none;
    animation: ${pulse} 500ms 500ms ease-in-out 4 alternate;
  }
  :hover:not(:nth-child(even)) {
    cursor: pointer;
    background: ${(props) => props.theme.primaryBg};
    transform: scale(1.05);
  }
  :hover > * {
    color: white;
  }
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: rotate(-45deg);
    font-weight: bold;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    width: min-content;
    @media screen and (max-width: 800px) {
      transform: rotate(0);
    }
  }
`;
