import React from "react";

import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import ServicesPageContent from "../components/ServicesPageContent/ServicesPageContent";

const ServicesPage = () => (
  <Layout>
    <SEO
      title="Cost-Effective Digital Solutions From a Web Development Company"
      description="As a digital marketing company, Serbay Digital offers a wide array of digital solutions designed to engage and convert customers for your business."
    />

    <ServicesPageContent></ServicesPageContent>
  </Layout>
);

export default ServicesPage;
