import styled from "styled-components";

export const FlipCardReverse = styled.div`
  grid-area: 1 / -1 / 1 / -1;
  max-width: 480px;
  min-height: 30%;
  backface-visibility: hidden;
  z-index: 1;
  transform: ${(props) => `rotateY(calc(180deg * ${+props.flipped} - 180deg))`};
  transition: transform 0.8s;
  display: grid;
  align-content: space-between;
  div:last-child {
    margin-left: auto;
  }
`;
