import styled from "styled-components";

export const ContentContainer = styled.section`
  margin: 0 auto;
  grid-column: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${(props) => (props.textContainer ? "70ch" : null)};
`;
