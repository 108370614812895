import React from "react";
import { Grid, GridItem } from "./ServicesFlipCardFront.styled";
import PropTypes from "prop-types";
import { Text } from "../../UI/Typography/Typography.styled";

function ServicesFlipCardFront({ flipped, content, onClick }) {
  return (
    <Grid flipped={flipped}>
      {content.map((item, i) => {
        if (item.title) {
          return (
            <GridItem key={item.title} onClick={() => onClick(item)}>
              <Text>{item.title}</Text>
            </GridItem>
          );
        }
        return <div key={i} aria-hidden="true"></div>;
      })}
    </Grid>
  );
}

ServicesFlipCardFront.propTypes = {
  content: PropTypes.array.isRequired,
  flipped: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default ServicesFlipCardFront;
