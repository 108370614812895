import React from "react";
import PropTypes from "prop-types";
import Button from "../../UI/Button/Button";
import { Text } from "../../UI/Typography/Typography.styled";
import { FlipCardReverse } from "./ServicesFlipCardReverse.styled";

function ServicesFlipCardReverse({ content, flipped, current, onClick }) {
  return (
    <FlipCardReverse flipped={flipped}>
      <Button type="button" secondary onClick={onClick}>
        Go Back
      </Button>
      <Text>
        {current && content.find((item) => item.title === current)[current]}
      </Text>
      <Button
        link={`/services/${current.toLowerCase().replace(/\s|\//g, "-")}`}
        type="button"
      >
        Read More
      </Button>
    </FlipCardReverse>
  );
}

ServicesFlipCardReverse.propTypes = {
  flipped: PropTypes.bool.isRequired,
  content: PropTypes.array.isRequired,
  current: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ServicesFlipCardReverse;
