import React from "react";
import ServicesListCard from "./ServicesListCard/ServicesListCard";
import PageContainer from "../Layout/PageContainer/PageContainer";
import { Text } from "../UI/Typography/Typography.styled";

const webDev = "custom website development",
  appDev = "mobile app development",
  uiDev = "website design",
  digMar = "digital marketing",
  seo = "search engine optimization";

const content = [
  {
    title: webDev,
    [webDev]:
      "In need of a website for your new business? Maybe it’s time to give your website a much-needed facelift to keep up with the times? Serbay Digital has years of experience in web development, and we’re more than happy to create blazing fast, user-centric websites for your brand. Your site will look fantastic and also easy to use. Our goal is to make visitors spend a long time on your website and turn them into loyal customers.",
  },
  {},
  {
    title: appDev,
    [appDev]:
      "Reach more potential clients by creating an app for your product or service.  Apps make it easy for your prospects to order from you using their smartphone. And with the right digital marketing campaign, you can increase your customer base exponentially. The best part? With Serbay Digital, you can roll out your very own mobile app in an affordable, cost-effective manner.",
  },
  {},
  {
    title: uiDev,
    [uiDev]:
      "User experience comes first when it comes to creating websites and mobile apps. No matter how many fancy features you have, if your user can’t figure it out due to a complicated mess of buttons, they’ll stop using your site or app. Work with us, and we’ll make it a stress-free frictionless user experience for your target audience. Increase your leads and sales with a great UX/UI design.",
  },
  {},
  {
    title: digMar,
    [digMar]:
      "While we specialize in website and app development, our work doesn’t stop there. We’ll help bring clients to your door through a properly-executed digital marketing campaign that combines SEO, social media, and PPC. You can build the best website for your business, but if you don’t have a marketing budget to drive traffic, no one will see it.",
  },
  {},
  {
    title: seo,
    [seo]:
      "Did you know that almost everyone searches for products and services on Google or Bing before deciding to buy? If your website isn’t showing up because the search engine can’t read it, your target market will have a hard time finding you. Hire us to optimize your website for search engines and increase your rankings so that you’re always at the top of the search engine results pages.",
  },
];

const ServicesPageContent = () => {
  return (
    <PageContainer
      title="HERE’S WHAT WE HAVE TO OFFER"
      subtitle="Grow Your Business With These Affordable Digital Marketing Solutions"
    >
      <Text>
        As a digital marketing company, Serbay Digital offers a vast array of
        solutions that can help your business grow amid an increasingly
        competitive digital landscape. We have experts on different aspects of
        web development and digital marketing, and all of us are working as a
        team to achieve a common goal: putting your business on the digital map.
      </Text>
      <Text>
        Create a memorable online presence that converts! We’ll help you
        increase unique visitors and convince customers to purchase your product
        or service.
      </Text>
      <Text>
        Have a bird’s eye view on the digital marketing solutions offered here
        at Serbay Digital.
      </Text>
      <ServicesListCard content={content}></ServicesListCard>
    </PageContainer>
  );
};

export default ServicesPageContent;
