import React, { useState } from "react";
import PropTypes from "prop-types";
import { ContentContainer } from "../../UI/ContentContainer/ContentContainer.styled";
import ServicesFlipCardReverse from "../ServicesFlipCardReverse/ServicesFlipCardReverse";
import { FlipCardContainer } from "../../UI/FlipCard/FlipCard.styled";
import ServicesFlipCardFront from "../ServicesFlipCardFront/ServicesFlipCardFront";

const ServicesListCard = ({ content }) => {
  const [flipped, setFlipped] = useState(false);
  const [current, setCurrent] = useState("");

  function handleFrontClick(item) {
    setFlipped(!flipped);
    setCurrent(item.title);
  }
  return (
    <ContentContainer>
      <FlipCardContainer>
        <ServicesFlipCardFront
          flipped={flipped}
          content={content}
          onClick={handleFrontClick}
        ></ServicesFlipCardFront>
        <ServicesFlipCardReverse
          flipped={flipped}
          content={content}
          current={current}
          onClick={() => setFlipped(!flipped)}
        ></ServicesFlipCardReverse>
      </FlipCardContainer>
    </ContentContainer>
  );
};

ServicesListCard.propTypes = {
  content: PropTypes.array.isRequired,
};

export default ServicesListCard;
